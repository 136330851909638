import Select from "component/fields/select";
import AreaChartComponent from "component/graph";
import LegendChartColor from "component/graph/legendChartColor";
import StackedBarChart from "component/graph/stackBarChart";
import { SelectComponent } from "component/index";
import { useGrowthGraph } from "hooks/dashboard";
import { HorizontalLine } from "page/style";
import { chartOptions, contentPeriodOptions, graphDataOptions } from "./list";
import { useOverallContentStats } from "hooks/dashboard";
import { useState } from "react";
import usePodcastStatistics from "hooks/dashboard/usePodcastStatistics";

const GraphsSection = () => {
  const [growthGraphData, createrViewer, setCreaterViewer, filterTime, setFilterTime, loading] =
    useGrowthGraph();
  const [contentPeriod, setContentPeriod] = useState(contentPeriodOptions[0].value);
  const { overallContentStats, loading: overallContentLoading } =
    useOverallContentStats(contentPeriod);
  const [period, setPeriod] = useState(graphDataOptions[0].value);
  const { podcastStats, loading: podcastStatsLoading } = usePodcastStatistics(period);

  const formattedData = {
    periodData: podcastStats.timeSeriesData.length
      ? podcastStats.timeSeriesData.map((item) => ({
          name: item.name,
          value: item.podcastCount
        }))
      : [{ name: "No data", value: 0 }]
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "34px", marginTop: "30px" }}>
      <div className="podcast-graph-wrapper">
        <div className="podcast-graph-header">
          <div className="heading">Content Stats</div>
          <div className="right-section light-txt">
            Total Uploads {overallContentStats?.totalUploads}
            <Select
              size="middle"
              options={contentPeriodOptions}
              value={contentPeriod}
              onChange={(value) => setContentPeriod(value)}
              customeStyle={{
                bg: "white",
                textColor: "black",
                optionsBg: "white"
              }}
              color={"black"}
            />
          </div>
        </div>

        <AreaChartComponent
          yLabel="Overall Content On Platform"
          data={overallContentStats}
          loading={overallContentLoading}
        />
      </div>

      <HorizontalLine margin="-10px 0 0 0" />

      <div className="podcast-graph-wrapper">
        <div className="podcast-graph-header">
          <div className="heading">User Growth</div>
          <LegendChartColor createrViewer={createrViewer} />

          <div className="right-section">
            <SelectComponent
              onChange={(e) => setCreaterViewer(e)}
              size="middle"
              value={chartOptions[0]}
              options={chartOptions}
              customeStyle={{
                bg: "white",
                textColor: "black",
                optionsBg: "white"
              }}
              color={"black"}
            />

            <SelectComponent
              onChange={(e) => setFilterTime(e)}
              size="middle"
              value={graphDataOptions[2]}
              options={graphDataOptions}
              customeStyle={{
                bg: "white",
                textColor: "black",
                optionsBg: "white"
              }}
              color={"black"}
            />
          </div>
        </div>

        <StackedBarChart
          createrViewer={createrViewer}
          filterTime={filterTime}
          data={growthGraphData}
          loading={loading}
        />
      </div>

      <HorizontalLine margin="-10px 0 0 0" />

      <div className="podcast-graph-wrapper">
        <div className="podcast-graph-header">
          <div className="heading">Financial Stats</div>
          <div className="right-section light-txt">
            Overall Earning: ${podcastStats.overview.totalEarnings.toLocaleString()}
            <Select
              size="middle"
              options={contentPeriodOptions}
              value={contentPeriod}
              onChange={(value) => setPeriod(value)}
              customeStyle={{
                bg: "white",
                textColor: "black",
                optionsBg: "white"
              }}
              color={"black"}
            />
          </div>
        </div>

        <AreaChartComponent
          yLabel="Number of Podcasts"
          data={formattedData}
          loading={podcastStatsLoading}
          dataKey="value"
        />
      </div>
    </div>
  );
};

export default GraphsSection;
