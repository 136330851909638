import { ButtonComponent } from "component/index";
import { message } from "antd";
import moment from "moment";

import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { downloadReportApi, getReportListingApi } from "service/api/report";
import { updateUserStatusApi } from "service/api/usermanagement";
import { errorMessage } from "util/commonSection";
import { pageLimit } from "util/constant";
import { theme } from "util/theme";

const useReport = () => {
  const [reportListing, setReportListing] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [userInfoId, setuserInfoId] = useState();
  const navigate = useNavigate();
  const getReports = useCallback(
    async (page = currentPage) => {
      setLoading(true);
      const params = new URLSearchParams();
      params.append("page", page);
      params.append("limit", pageLimit);
      if (fromDate && endDate) {
        params.append("from", fromDate);
        params.append("to", endDate);
      }

      const res = await getReportListingApi(params);

      if (res?.status === 200) {
        const { data = [], count = 0 } = res;
        setReportListing(data);
        setTotalPage(count);
      } else message.error(res?.message || "Something went wrong");
      setLoading(false);
    },
    [currentPage, endDate, fromDate]
  );

  const handleDateChange = (e, type = "") => {
    const val = e === "Invalid Date" ? "" : e;
    if (type === "from") {
      setFromDate(val);
    } else {
      setEndDate(val);
    }
  };

  const handlePageChange = (pageNumber) => {
    getReports(pageNumber);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (fromDate && endDate) {
      getReports(1);
      setCurrentPage(1);
    }
  }, [fromDate, endDate, getReports]);

  const handleDownload = async (e) => {
    const payload = new URLSearchParams();
    payload.append("report_id", e?._id);
    const req = await downloadReportApi(payload);
    if (req?.status == 200) {
      
      window.open(req.data)
    } else {
      errorMessage(req);
    }
  };

  const handleOnChange = async (e, value) => {
    if (e?.reported_user?.status == "inactive") {
      const payload = {
        user_id: e?.reported_user?._id,
        status: "active"
      };
      const req = await updateUserStatusApi(payload);
      if (req?.status === 200) {
        message.success("Successfully Updated");
        getReports();
      } else {
        errorMessage(req);
      }
    } else {
      setOpenModal((prev) => !prev);
      {
        value && setuserInfoId(e?.reported_user?._id);
      }
    }
  };

  const changeStatus = () => {
    getReports();
  };

  useEffect(() => {
    getReports();
  }, [getReports]);
  const columns = [
    {
      title: "S. No.",
      dataIndex: "key",
      key: "key",
      align: "center",
      render: (_, record, index) => (currentPage - 1) * pageLimit + index + 1,
      width: 60
    },
    {
      title: "Reported Person",
      dataIndex: "reported_person",
      key: "reported_person",
      align: "center",
      render: (_, record) => (
        <>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/report-details", { state: record })}>
            {record?.reported_user?.full_name}
          </div>{" "}
        </>
      ),
      width: 130
    },
    {
      title: "Email Id",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (_, record) => record?.reported_user?.email,
      width: 210
    },
    {
      title: "User Name",
      dataIndex: "user_name",
      key: "user_name",
      align: "center",
      render: (_, record) => record?.reported_user?.user_name,
      width: 120
    },
    {
      title: "Reported by",
      dataIndex: "reported_by",
      key: "reported_by",
      align: "center",
      render: (_, record) => record?.reported_by_user?.user_name,
      width: 130
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      align: "center",
      width: 90,
      render: (_, record) => moment(record?.updatedAt).format("DD-MMM-YY")
    },
    {
      title: "Time",
      dataIndex: "time",
      key: "time",
      align: "center",
      width: 95,
      render: (_, record) => moment(record?.updatedAt).format("hh:mm a")
    },
    {
      title: "Report Type",
      dataIndex: "report_type",
      key: "report_type",
      align: "center",
      width: 180,
      render: (_, record) => <>{record?.type === 4 ? "Copyright Infringement" : record?.reason} </>
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      align: "center",

      render: (_, record) => (
        <div className="btn-wrapper" style={{ display: "flex", justifyContent: "space-around" }}>
          <div className="download-btn" onClick={() => handleOnChange(record, true)}>
            {record?.reported_user?.status == "inactive" ? "Enable" : "Disable"}
          </div>
          <div>
            <ButtonComponent
              bg={theme.primaryColor}
              text="Download"
              height={"30px"}
              width={"100px"}
              onClick={() => handleDownload(record)}
            />
          </div>
        </div>
      ),
      width: 250
    }
    // {
    //   title: "Status",
    //   dataIndex: "status",
    //   key: "status",
    //   align: "center",
    //   width: 60,
    //   render: () => (
    //     <div className="btn-wrapper">
    //       <div className="download-btn">Download</div>
    //     </div>
    //   ),
    // },
  ];
  return {
    reportListing,
    loading,
    totalPage,
    currentPage,
    handleDateChange,
    handlePageChange,
    columns,
    openModal,
    handleOnChange,
    userInfoId,
    changeStatus
  };
};

export default useReport;
