
import styled from "styled-components";
import { Col, Image, Row } from "antd";
import { FileImageOutlined } from "@ant-design/icons";
import { theme } from "util/theme";
import Loader from "component/loader";
import { PersonIcon } from "util/svgFile";

export default function ProfileInfoCard({
  list = {},
  loading,
  align,
  type = "creator",
}) {
  const {
    profilePic = "",
    coverImage = null,
    ...rest
  } = list || {};
  
  if (loading) return <Loader />;
  
  return (
    <ProfileInfoWrapper className="profile-wrapper" align={align}>
      <div className="heading">Personal Details</div>
      {list ? (
        <Row gutter={[20,0]}>
          {type === "viewer" ? (
            <Col span={6} className="image-wrapper">
              {!profilePic ? (
                <div className="no-image">
                  <PersonIcon height="60px" width="60px" />
                </div>
              ) : (
                <Image
                  height="150px"
                  width="120px"
                  src={profilePic}
                  className="fit-image"
                  style={{ borderRadius: "8px" }}
                />
              )}
            </Col>
          ) : (
            <Col span={8} className="creator-section image-wrapper">
              {coverImage ? (
                <Image
                  className="fit-image"
                  height="150px"
                  width="100%"
                  src={coverImage}
                  style={{ borderRadius: "8px" }}
                />
              ) : (
                <div className="not-found-cover">
                  <FileImageOutlined
                    style={{
                      fontSize: "32px",
                    }}
                  />
                </div>
              )}
              {profilePic ? (
                <div className="creator-profile-image">
                  <Image
                    height="100%"
                    width="100%"
                    src={profilePic}
                    alt="profile"
                  />
                </div>
              ) : (
                <div className="no-image creator-profile-image">
                  <PersonIcon height="60px" width="60px" />
                </div>
              )}
            </Col>
          )}
          <Col span={type === "viewer" ? 18 : 16}>
            <div className="box">
              {Object.entries(rest).map(([key, value]) =>
                key !== "Social Links" ? (
                  <div className="info" key={key}>
                    <div className="key" style={{textAlign:"left"}} >{key}:</div>
                    <div className="value">{value || "N/A"}</div>
                  </div>
                ) : (
                  <div className="info" key={key}>
                    {value.length > 0 ? <div className="key">{key}:</div> : ""}
                    <div className="flex-wrap">
                      {value.map((list1,i) => (
                        list1?.value &&<div className="value social-icon" key={i}>
                          {list1.icon}
                         <p className="social-value">  {list1.value}</p> 
                        </div>
                      ))}
                    </div>
                  </div>
                )
              )}
            </div>
          </Col>
        </Row>
      ) : (
        <NoData>No Data</NoData>
      )}
    </ProfileInfoWrapper>
  );
}
const NoData = styled.div`
  display: flex;
  padding: 15px;
  align-items: center;
  justify-content: center;
  color: ${theme.greyText};
`;

const ProfileInfoWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid ${theme.greyBorder};
  .heading {
    background: ${theme.buttonColor};
    padding: 12px 18px;
    border-radius: 6px;
    color: ${theme.lightWhite};
  }
  .ant-row {
    padding: 20px 10px 40px 10px;
  }
  .image-wrapper {
    display: flex;
    justify-content: center;
  }
  .creator-section {
    position: relative;
    // background: ${theme.buttonColor};
  }
  .fit-image {
    object-fit: cover;
  }
  .not-found-cover {
    display: flex;
  }
  .creator-profile-image {
    position: absolute;
    height: 80px !important;
    width: 80px !important;
    border-radius: 50px;
    overflow: hidden;
    bottom: -35px;
    left: 25px;
  }
  .no-image {
    width: 94px;
    height: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: lightgrey;
    border-radius: 50%;
    margin: auto;
  }
  .info {
    display: flex;
    padding: 2px;
  }
  .key {
    width: 100px;
    margin-right: 20px;
    text-align: ${(props) => props.align || "left"};
    font-size: 13px;
    text-transform: capitalize;
  }
  .value {
    text-align: left;
    max-width: 280px;
    // max-width: calc(100% - 120px);
    word-wrap: break-word;
  }

  .box {
    color: ${theme.lightWhite};
    height: 150px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 18px;
    overflow: auto;
    padding: 0 5px;
    // grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  }
  .box::-webkit-scrollbar {
    width: 0px;
  }
  // .box > * {
  //   flex: 1 1 8px;
  // }
  .flex-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 130px;
    word-wrap: break-word;
    gap: 10px;
  }
  .social-icon {
    font-size: 10px;
    display: flex;
    align-items:start;
    width:100%;
    gap: 10px;
    svg {
      height: 18px;
      width: 18px;
    }
      .social-value{
      font-size:15px;
      }
  }
`;
