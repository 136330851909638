import { useCallback, useEffect, useState } from "react";
import { message } from "antd";
import { userDetailApi } from "service/api/usermanagement";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { podcastCardList } from "page/userManagement/data";
import { calculateAge } from "component/age";

const useUserProfile = (info) => {
  const location = useLocation().pathname;
  const [userProfile, setUserProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [allDetails, setAllDetails] = useState({});
  const [podcastCardInfo, setPodcastInfo] = useState(podcastCardList);

  const getUserDetail = useCallback(async () => {
    setLoading(true);
    const res = await userDetailApi(info);
    if (res?.status === 200) {
      const detail = res?.data[0] || {};
      const firstName = detail?.first_name ?? "";
      const lastName = detail?.last_name ?? "";
      const profilePic = detail?.profile_pic_url;
      const filterData = {
        "Name of User": `${firstName} ${lastName}`,
        Username: detail?.user_name,
        "Email Address": detail?.email,
        Gender: detail?.userDetail?.gender,
        "Phone Number": `${detail?.country_code} ${detail?.phone_number}`,
        "Date Joined": moment(detail?.createdAt).format("DD-MM-yyyy") ?? "N/A",
        status: detail?.status,
        
        "age": calculateAge(detail?.userDetail?.dob),
        profilePic
      };
      if (location.includes("creator-profile")) {
        filterData["About Me"] = detail?.userDetail?.about_me;
        filterData["coverImage"] = detail?.userDetail?.cover_photo_url;
      }

      setUserProfile(filterData);
      if (location.includes("creator-profile")) {
        setAllDetails(detail);
        const updatePodcastInfo = (pre) => {
          const updatePodcast = [...pre];
          updatePodcast[0].count = detail?.contentCount;
          updatePodcast[1].count = detail?.audioContentCount;
          updatePodcast[2].count = detail?.videoContentCount;
          return updatePodcast;
        };
        setPodcastInfo((pre) => updatePodcastInfo(pre));
      }
    } else {
      setUserProfile(null);
      message.error(res?.message);
    }
    setLoading(false);
  }, [info, location]);

  useEffect(() => {
    getUserDetail();
  }, [getUserDetail]);

  return [userProfile, loading, getUserDetail, allDetails, podcastCardInfo];
};

export default useUserProfile;
