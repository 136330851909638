export const theme = {
  primaryColor: "rgba(164, 22, 20, 1)",
  lightPrimaryColor: "rgba(216, 12, 12, 1)",
  fieldBg: "rgba(229, 229, 229, 0.56)",
  white: "white",
  black: "rgba(0,0,0)",
  sidebar: "rgba(33, 33, 33, 1)",
  lightGreyText: "rgba(255, 255, 255, 0.72)",
  screenBackground: "rgba(19, 19, 19, 1)",
  buttonColor: "rgba(179, 179, 179, 0.2)",
  pagination: "rgba(255, 255, 255, 0.25)",
  greyText: "rgba(116, 116, 116, 1)",
  lightWhite: "rgba(255, 255, 255, 0.79)",
  greyBorder: "rgba(255, 255, 255, 0.37)",
  grey2: "rgba(255, 255, 255, 0.1)",
  tableHeader: "rgba(255, 255, 255, 0.05)",
  darkGreyText: "rgba(0, 0, 0, 0.7)",
  lightGreen: "rgba(105, 144, 111, 1)",
  formField: "rgba(196,196,196,0.1)",
  modalgrey:"rgba(86, 83, 83, 1)",
  darkblue: "rgba(4, 14, 253, 1)",
  red: "rgba(250, 12, 12, 1)",
  green: "rgba(48, 218, 20, 1)",
  greyButton: "rgba(196, 196, 196, 0.24)",
  purple: "rgba(82, 70, 196, 0.78)",
  sidebarSelect: "rgba(196, 196, 196, 0.6)",
  buttonDarkColor: "rgba(0, 0, 0, 0.73)",
  lightGrey: "rgba(0, 0, 0, 0.08)",
  midGrey: "rgba(196, 196, 196, 0.45)",
};

export const font = {
  smallFont: "12px",
  normal: "14px",
  mid16: "16px",
  small13: "13px",
  mid: "18px",
};
