import ReactDOM from "react-dom/client";
import "index.css";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import App from "App";
import reportWebVitals from "reportWebVitals";
import store from "store/reduxStore";
import { theme } from "util/theme";
import * as Sentry from "@sentry/react";



Sentry.init({
  dsn: "https://a29011d63fbc2bcc52774a66b9852826@o4507931989573632.ingest.us.sentry.io/4508760539267072",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Sentry.ErrorBoundary fallback={<p>Something went wrong. Please try again later.</p>}>
   
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: theme.greyText,
        colorPrimaryHover: theme.midGrey,
        colorText: theme.white
      }
    }}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ConfigProvider>
  </Sentry.ErrorBoundary>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
