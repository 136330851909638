import { CashIcon, UserIcon } from "util/svgFile";

export const filterButtons = [
  { name: "Day", value: 1 },
  { name: "Week", value: 7 },
  { name: "Month", value: 30 },
  { name: "Year", value: 365 }
];

export const lists = [
  {
    count: "10,092",
    heading: "Total Registered Viewers",
    icon: <UserIcon height="22px" width="22px" opacity={1} />
  },
  {
    count: "1,003",
    heading: "Total Creators",
    icon: <UserIcon height="22px" width="22px" opacity={1} />
  },
  {
    count: "11,009",
    heading: "Overall Users",
    icon: <UserIcon height="22px" width="22px" opacity={1} />
  }
];

export const countList = {
  heading: "Earnings",
  count: "$2003",
  icon: <CashIcon height="22px" width="22px" color={"white"} opacity={1} />
};

export const graphDataOptions = [
  {
    value: 30,
    label: "Month view"
  },
  {
    value: 7,
    label: "Weekly view"
  },
  {
    value: 365,
    label: "Yearly view"
  }
];

export const contentPeriodOptions = [
  {
    value: "week",
    label: "Weekly view"
  },
  {
    value: "month",
    label: "Monthly view"
  },
  {
    value: "year",
    label: "Yearly view"
  }
];

export const chartOptions = [
  {
    value: 3,
    label: "All"
  },
  {
    value: 2,
    label: "Creator"
  },
  {
    value: 1,
    label: "Viewer"
  }
];

export const Colorlabel = [
  {
    name: "Viewer",
    color: "#54a130",
    value: 1
  },
  {
    name: "Creator",
    color: "#47862a",
    value: 2
  }
];

export const Revenuelist = [
  {
    total_revenue: "104k",
    total_revenue_full: "104,000",
    date: "July 2024"
  },
  {
    total_revenue: "104k",
    date: "August 2024",
    total_revenue_full: "104,000"
  },
  {
    total_revenue: "104k",
    date: "October 2024",
    total_revenue_full: "104,000"
  }
];

export const dummyPodcastData = [
  {
    name: "Jan",
    view: "20K",
    uv: 4000,
    pv: 20,
    amt: 2400,
    unit: "Podcast"
  },
  {
    name: "Feb",
    view: "24K",
    uv: 3000,
    pv: 24,
    amt: 2210,
    unit: "Podcast"
  },
  {
    name: "Mar",
    view: "28K",
    uv: 2000,
    pv: 28,
    amt: 2290,
    unit: "Podcast"
  },
  {
    name: "Apr",
    view: "30K",
    uv: 2780,
    pv: 30,
    amt: 2000,
    unit: "Podcast"
  },
  {
    name: "May",
    view: "36K",
    uv: 1890,
    pv: 36,
    amt: 2181,
    unit: "Podcast"
  },
  {
    name: "June",
    view: "38K",
    uv: 2390,
    pv: 38,
    amt: 2500,
    unit: "Podcast"
  },
  {
    name: "July",
    view: "40K",
    uv: 3490,
    pv: 40,
    amt: 2100,
    unit: "Podcast"
  },
  {
    name: "Aug",
    view: "24K",
    uv: 3000,
    pv: 24,
    amt: 2210,
    unit: "Podcast"
  },
  {
    name: "Sep",
    view: "28K",
    uv: 2000,
    pv: 28,
    amt: 2290,
    unit: "Podcast"
  },
  {
    name: "Oct",
    view: "30K",
    uv: 2780,
    pv: 30,
    amt: 2000,
    unit: "Podcast"
  },
  {
    name: "Nov",
    view: "36K",
    uv: 1890,
    pv: 36,
    amt: 2181,
    unit: "Podcast"
  }
];
