import { constant, folderName } from "util/constant";
import { s3, S3_BUCKET } from "./awsConfig";
import store from "store/reduxStore";

const CDN_URL = process.env.REACT_APP_CDN_URL;
const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB per chunk

const uploadFileToS3 = async (file, upload_type, setUploadProgress = () => {}) => {
  try {
    const folder = folderName[upload_type] ?? constant.PROFILE_FOLDER;
    const fileName = file.name.split(".");
    const user_id = store.getState()?.signIn?.data?._id;

    // key name only used to create the file upload path in AWS
    const key_name = `${user_id}/${folder}/media-${Date.now()}.${fileName[fileName.length - 1]}`;
    const url = `${CDN_URL}${key_name}`;

    // Start multipart upload and get upload ID
    const createMultipartUploadParams = {
      Bucket: S3_BUCKET,
      Key: key_name,
      ContentType: file.type
    };

    const { UploadId } = await s3.createMultipartUpload(createMultipartUploadParams).promise();

    const totalParts = Math.ceil(file.size / CHUNK_SIZE);
    const parts = [];

    for (let partNumber = 1; partNumber <= totalParts; partNumber++) {
      const start = (partNumber - 1) * CHUNK_SIZE;
      const end = Math.min(start + CHUNK_SIZE, file.size);
      const partParams = {
        Body: file.slice(start, end),
        Bucket: S3_BUCKET,
        Key: key_name,
        PartNumber: partNumber,
        UploadId
      };
      const { ETag } = await s3.uploadPart(partParams).promise();
      parts.push({ PartNumber: partNumber, ETag });
      const percentCount = Math.round((partNumber / totalParts) * 100);
      setUploadProgress(percentCount); // used to show the percentage count of upload content
    }

    const completeMultipartUploadParams = {
      Bucket: S3_BUCKET,
      Key: key_name,
      UploadId,
      MultipartUpload: { Parts: parts }
    };

    const result = await s3.completeMultipartUpload(completeMultipartUploadParams).promise();

    if (result.$response?.httpResponse?.statusCode === 200)
      return { status: 200, data: { url, key_name } };
    return { staus: 500, message: "Error in uploading content" };
  } catch (error) {
    return { status: 500, error };
  }
};

export default uploadFileToS3;
