

import FormWrapper from "..";
import ForgotForm from "./form";

export default function ForgotPassword() {
  return (

    <FormWrapper>
      <ForgotForm />
    </FormWrapper>
   
  );
}
